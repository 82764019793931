/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import React, {memo} from "react";
import ContactUs from "../../common/ContactUs";
import Customers from "../../common/Customers";
import Footer from "../../common/Footer";
import Page from "../../common/Page";
import Meta from "../../common/Meta";
import Section from "../../common/Section";
import ServicesHero from "../../services/ServicesHero";
import {H1} from "../../common/typography/Headings";

const ServicesJa = () => (
    <Page path="/ja/services/" lang="ja" title="Services">
        <Meta name="description">
            私たちはスタートアップから大企業まで技術的に難しい課題を解決するためサポートします。
        </Meta>
        <ServicesHero>
            <H1>サービス</H1>
            <p>
                私たちはスタートアップから大企業まで技術的に難しい課題を解決するためサポートします。
            </p>
        </ServicesHero>

        <Section title="検索">
            <p>
                私たちは検索のエキスパートです。検索システムのあらゆるところの改善にお手伝いできます。
                検索システムのカスタム化からLucene、Solr、またはelasticsearchの大規模のデプロイにもご支援できます。
            </p>
        </Section>

        <Section title="NLP" grey>
            <p>
                私たちは東アジアと西洋のテキストの言語処理に幅広い経験を持っています。
                形態素解析、キーフレーズ及び概念抽出、固有表現抽出、感情分析の経験があります。
            </p>
        </Section>

        <Section title="機械学習 &amp; AI">
            <p>
                機械学習とディープラーニングの最先端の技術を活用しながら、複雑な課題に取り組んでビッグデータを使って
                新規性の高い解決策を提供します。
            </p>
        </Section>

        <Section title="アプリケーションのプロトタイプ" grey>
            <p>
                私たちはスタック全体の経験を持っており、お客様と一緒に新規機能のアプケーションプロトタイプまたは新しい製品を
                迅速に開発できます。
            </p>
        </Section>

        <Customers title="顧客" />

        {/*<Testimonials title="お客様の声">*/}
        {/*<Testimonial title="Acme, Inc - J. Doe">*/}
        {/*<p>*/}
        {/*Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
        {/*Ut tincidunt metus eget velit convallis, ut placerat sem aliquet.*/}
        {/*Aliquam hendrerit.*/}
        {/*</p>*/}
        {/*</Testimonial>*/}

        {/*<Testimonial title="Acme, Inc - J. Doe">*/}
        {/*<p>*/}
        {/*Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
        {/*Ut tincidunt metus eget velit convallis, ut placerat sem aliquet.*/}
        {/*Aliquam hendrerit.*/}
        {/*</p>*/}
        {/*</Testimonial>*/}

        {/*<Testimonial title="Acme, Inc - J. Doe">*/}
        {/*<p>*/}
        {/*Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
        {/*Phasellus in turpis feugiat neque elementum dictum vitae vel sem.*/}
        {/*In hac habitasse platea dictumst. Donec vulputate.*/}
        {/*</p>*/}
        {/*</Testimonial>*/}

        {/*<Testimonial title="Acme, Inc - J. Doe">*/}
        {/*<p>*/}
        {/*Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
        {/*In et dui quis elit malesuada ultrices.*/}
        {/*Etiam vitae urna at dolor mollis maximus.*/}
        {/*Quisque risus mauris, elementum id euismod a, fermentum.*/}
        {/*</p>*/}
        {/*</Testimonial>*/}
        {/*<Testimonial title="Acme Inc, Pieter-Jan">*/}
        {/*<p>*/}
        {/*Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
        {/*Suspendisse eget augue magna.*/}
        {/*Aenean urna magna, congue id nisi at, euismod blandit est.*/}
        {/*Morbi sit amet hendrerit massa, sed fringilla odio.*/}
        {/*Sed vel ornare purus.*/}
        {/*In lobortis, metus sit amet faucibus consequat,*/}
        {/*urna turpis facilisis nunc, sit amet finibus purus mi.*/}
        {/*</p>*/}
        {/*</Testimonial>*/}
        {/*</Testimonials>*/}

        <ContactUs title="Let’s get to work">
            <p>プロジェクトのご相談を承っています。ご連絡をお待ちしております。</p>
        </ContactUs>

        <Footer lang="ja" />
    </Page>
);

export default memo(ServicesJa);
